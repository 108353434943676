.coords {
    position: absolute;
    z-index: 999;
    bottom: 0;
    right: 0;
    background-color: white;
    opacity: 0.7;
    border-top-left-radius: 10;
    display: flex;
}

.infoPanel {
    z-index: 995;
    position: absolute;
    top: 10vh;
    left: 0;
    width: 300px;
    height: 90vh;
    background-color: #fff;
}

.panelOpener {
    z-index: 995;
    position: absolute;
    top: 45vh;
    left: 0;
    width: 20px;
    height: 10vh;
    background-color: #fff;
    cursor: pointer;
    border-top-right-radius: 2vh;
    border-bottom-right-radius: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelOpener:hover {
    background-color: #fff;
    opacity: 0.8;
}

.panelCloser {
    z-index: 995;
    position: absolute;
    top: 10vh;
    left: 300px;
    width: 20px;
    height: 90vh;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.panelCloser:hover {
    background-color: #fff;
    opacity: 0.8;
}
  
.arrow {
    width: 75%;
    height: 75%;
}

.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    width: 100%;
    padding: 10px;
}

.image {
    max-height: 50vh !important;
    height: auto !important;
    width: 100% !important;
    max-width: 100% !important;
    display: flex;
}

.imageSides {
    width: 10%;
    background-color: #fff;
    z-index: 3;
    height: 100% !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageContainerContainer {
    width: auto; 
    max-width: 80%;
    height: auto;
    display: 'flex'; 
    justify-content: 'center';
    align-items: 'center';
    cursor: pointer;
}

.iconLabelDiv {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.iconDiv {
    width: 12%;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.iconDiv:hover {
    background-color: #e7f1ff;
}

.itemUpload {
    display: block;

}

.icon:hover {
    transform: scale(1.2);
}

.icon {
    cursor: pointer;
    padding: 8px;
    height: 40px;
}

.modal-90w {
    width: 90vw;
    max-width: 90vw;
    height: 80% !important;
    max-height: 80% !important;
}



/* Image Upload */
.imageUploadPreviewDiv {
    height: 32vh;
    display: grid;
    grid-template-columns: 25.5% 74.5%;
    grid-gap: 5;
    align-items: center;
}

.imagePreviewDiv {
    height: 32vh;
    padding: 1vh;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;

}

.imagePreviewDiv::-webkit-scrollbar {
    height: 5;
}

.imagePreviewDiv::-webkit-scrollbar-thumb {
    background: rgba(200, 200, 200, 0.8);
    border-radius: 10px;
}

.dropDiv {
    border-width: 1px;
    border: solid;
    border-color: rgba(219, 219, 219, 1);
    border-radius: 30px;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: black;
    width: 100%;
    text-align: center;
}

.imageDiv {
    padding: 5;
    border-radius: 10;
    position: relative;
    cursor: pointer;
}

.imagePreview {
    height: 30vh;
}

.deleteDiv {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    z-index: 999;
}

.deleteDiv:hover {
    transform: scale(1.5);
    transition: 0.2s;
}

.watermarkDiv {
    margin-top: 15;
    width: 30%;
}

.checkboxes {
    display: flex;
    flex-direction: column;
}

.searchBox {
    position: absolute;
    z-index: 994;
    right: 2%;
    top: 12vh;
}
/* 
@media only screen and (max-width: 600px) {
    .searchBox {
        position: absolute;
        z-index: 994;
        right: 2%;
        top: 18vh;
    }
} */

.resultBox {
    background-color: #fff;
    padding: 2px;
    cursor: pointer;
    /* border-radius: 10px; */
    padding: 5px;
}

.resultBox:hover {
    background-color: #e7f1ff;
}

.resultBoxBox {
    position: absolute;
    background-color: 'white';
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    z-index: 999;
    width: 25%;
}

.itemBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
    max-height: 80%;
    padding: 5px;
    overflow-x: hidden;
}

.video {
    width: 80%;
    height: 100%;
    border: 0,
}

.imagePopupContainerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 50vh;
}

.imagePopupContainer {
    width: 65%;
    max-height: 50vh;
}

.legendContainer {
    max-height: 60vh;
    overflow-y: scroll;
}

.legendContainer::-webkit-scrollbar {
    display: none;
}

.mainItemContainer {
    max-height: 55vh;
    overflow-y: scroll;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
}

@media only screen and (min-height: 775px) { 
    .mainItemContainer {
        max-height: 65vh;
    }
}

@media only screen and (min-height: 675px) and (max-height: 775px) { 
    .mainItemContainer {
        max-height: 60vh;
    }
}

@media only screen and (max-height: 575px) { 
    .mainItemContainer {
        max-height: 50vh;
    }
}

.legendIconContainer {
    display: flex;
    justify-content: flex-start;
}

.legendIcon {
    height: 4vh;
    width: auto;
    display: flex;
    align-self: center;
    justify-content: center;
}

.buildingTagBox {
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: whitesmoke;
    border-radius: 5px;
    display: flex;
}

.buildingTag {
    cursor: pointer;
    /* height: 60px; */
    background-color: #fff;
    border-radius: 5px;
    padding: 0.5rem;
    margin: 0.25rem;
    border-radius: 3;
    display: inline-block;
}

.buildingTag:hover {
    transform: scale(1.1)
}

.institutionBox {
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 5px;
}

.captializeFirst::first-letter {
    text-transform: capitalize;
}

.navbar {
    height: 10vh;
    background-color: #fff !important;
}

.mapContainer {
    height: 86vh;
}

.mapDiv {
    height: 90vh;
    width: 100%;
    cursor: pointer;
}

/* .logoDiv {
    height: 40vh;
    background-color: #fff;
    top: -24vh;
    position: absolute;
    width: 40vh;
    border-radius: 50%;
    z-index: 999;
} */

.logo {
    height: 8vh;
    top: 0;
    z-index: 999;
    margin: 1vh;
}

.header {
    height: 10vh;
    background-color: #fff;
}

.leftHeader {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 10vh;
    width: 30vw;
    display: flex;
    justify-content: flex-start;
}

.leftLink {
    margin: 2vh;
    height: 6vh;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
}

.rightHeader {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 10vh;
    width: 30vw;
    display: flex;
    justify-content: flex-end;
}



@media only screen and (max-width: 600px) {
    .logo {
        position: absolute;
        height: 8vh;
        top: 0;
        left: 0;
        z-index: 999;
        margin: 1vh;
    }

    .leftHeader {
        position: absolute;
        left: 15vh;
        top: 0px;
        height: 10vh;
        width: 30vw;
        display: flex;
        justify-content: flex-start;
    }
}

.footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 999;
    background-color: #fff;
}
  
.footer-font {
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    height: 100%;
}

.copyright-text {
    padding-top: 0.3vh;
}

.social-logo {
    font-size: 1.1 rem;
    color: black;
}

.errorScreen{
    height: 86vh;
    width: 100%;
    cursor: pointer; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    background-color: black;
    color: white;
    position: absolute;
    top: 10vh;
}

.profileBox:hover {
    background-color: rgb(201, 201, 201);
}

.profileImage {
    height: 5vh;
    width: auto;
    border-radius: 50%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 5px;
}

.profileBox {
    position: absolute;
    height: 6vh;
    top: 2vh;
    right: 10px;
    width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border-radius: 2vh;
    z-index: 997;
    background-color: #fff;
}

@media only screen and (max-width: 500px) {
    .profileBox {
        height: 6vh;
        width: 6vh;
        justify-content: center;
    }
    .profileName {
        display: none;
    }
    .profileImage {
        margin-right: 0px;
    }
}

.logoutMenu {
    position: absolute;
    right: 10px;
    top: 2vh;
    padding-top: 6vh;
    background-color: #fff;
    z-index: 996;
    width: 200px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 2vh;
}


.loginButton {
    margin: 2vh;
    height: 6vh;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoutButton {
    margin: 2vh;
    height: 6vh;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
}

.formPage {
    margin-top: 10vh;
    height: 80vh;
    margin-left: 20vw;
    width: 60vw;
}

.zoomAlert {  
    width: 200px;
    z-index: 999;
    text-align: center;
}

.zoomAlertDiv {
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 7vh;
}

/*Scrollbar*/
/* width */
::-webkit-scrollbar {
    width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
    border: 4px rgba(255, 255, 255, 1) solid;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(120, 120, 120, 0.4);
    border-radius: 4px;
    border-left: 4px rgba(255, 255, 255, 1) solid;
    border-right: 4px rgba(255, 255, 255, 1) solid;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(120, 120, 120, 0.8);
    border-radius: 4px;
    border-left: 4px rgba(255, 255, 255, 1) solid;
    border-right: 4px rgba(255, 255, 255, 1) solid;
}